// @flow

import type { Placement } from '@popperjs/core/lib/enums';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import Tippy from '@tippyjs/react/headless';
import { motion, useSpring } from 'framer-motion';
import { Colors } from 'styles';

const springConfig = {
  damping: 80,
  stiffness: 800,
};

const INITIAL_SCALE = 0.85;
const HIDE_SCALE = 0.95;

const MotionDiv = motion.div;

export type TooltipProps = {
  children: React$Node,
  content?: string | React$Node,
  backgroundColor?: string,
  color?: string,
  centerContent?: boolean,
  placement?: Placement,
  ...
};

function Tooltip({
  children,
  content,
  backgroundColor = Colors.blue3,
  color = Colors.white,
  centerContent = false,
  ...tippyProps
}: TooltipProps): React$Node {
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(INITIAL_SCALE, springConfig);

  function internalOnMount() {
    scale.set(1);
    opacity.set(1);
  }

  function internalOnHide(instance: $FlowFixMe) {
    const cleanup = scale.onChange((value) => {
      if (value <= HIDE_SCALE) {
        cleanup();

        if (!instance.state.isDestroyed) {
          instance.unmount();
        }
      }
    });

    scale.set(HIDE_SCALE);
    opacity.set(0);
  }

  function internalOnHidden() {
    scale.set(INITIAL_SCALE);
  }

  return (
    <Tippy
      {...tippyProps}
      content={content}
      render={(attrs, singletonContent) => {
        const contentToShow = singletonContent ?? content;
        return contentToShow != null ? (
          <MotionDiv
            {...attrs}
            data-testid="tooltip"
            style={{ opacity, scale }}
            css={`
              padding: 0.3rem 0.6rem;
              font-weight: 500;
              border-radius: 0.5rem;
              font-size: 1.2rem;
              background-color: ${backgroundColor};
              color: ${color};
              text-align: ${centerContent ? 'center' : 'inherit'};
              z-index: 10000000000;
            `}
          >
            {contentToShow}
          </MotionDiv>
        ) : null;
      }}
      animation={true}
      onMount={internalOnMount}
      onHide={internalOnHide}
      onHidden={internalOnHidden}
    >
      {children}
    </Tippy>
  );
}

export default Tooltip;
