// @flow
import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  localStoragePersisterEffect,
  broadcastChannelSynchronizerEffect,
} from 'utils/recoilEffects';
import type { Key } from 'slate-react';
import type { PlaceholderPluginElement } from '../RichTextEditor/plugins/placeholder/types';
import type { ProcedureScope } from 'common/Procedures';
import type { PathRefType, NodeType } from 'slate';
import { NOOP } from 'config/constants';

export const ReportStatus: {
  Init: 'init',
  Idle: 'idle',
  Submitting: 'submitting',
  ProvisionalSubmit: 'provisionalReportSubmit',
  Submitted: 'submitted',
  Cancelling: 'cancelling',
  AddendumAdd: 'addendumAdd',
  AddendumSubmitting: 'addendumSubmitting',
  ProvisionalAddendumSubmit: 'provisionalAddendumSubmit',
  AddendumCancelling: 'addendumCancelling',
} = Object.freeze({
  Init: 'init',
  Idle: 'idle',
  Submitting: 'submitting',
  ProvisionalSubmit: 'provisionalReportSubmit',
  Submitted: 'submitted',
  Cancelling: 'cancelling',
  AddendumAdd: 'addendumAdd',
  AddendumSubmitting: 'addendumSubmitting',
  ProvisionalAddendumSubmit: 'provisionalAddendumSubmit',
  AddendumCancelling: 'addendumCancelling',
});

type TReportStatus = typeof ReportStatus;
export type ReportStatuses = $Values<TReportStatus>;
export type TTouchedFieldState = Map<Key, PathRefType>;

export const REPORT_EDITABLE_STATUSES: [
  TReportStatus['Init'],
  TReportStatus['Idle'],
  TReportStatus['AddendumAdd'],
] = [ReportStatus.Init, ReportStatus.Idle, ReportStatus.AddendumAdd];

export const PROVISIONAL_SUBMIT_STATUSES: [
  TReportStatus['ProvisionalSubmit'],
  TReportStatus['ProvisionalAddendumSubmit'],
] = [ReportStatus.ProvisionalSubmit, ReportStatus.ProvisionalAddendumSubmit];

export const IN_FLIGHT_STATUSES: [
  TReportStatus['Submitting'],
  TReportStatus['Cancelling'],
  TReportStatus['AddendumSubmitting'],
  TReportStatus['AddendumCancelling'],
] = [
  ReportStatus.Submitting,
  ReportStatus.Cancelling,
  ReportStatus.AddendumSubmitting,
  ReportStatus.AddendumCancelling,
];

export const ADDENDUM_STATUSES: [
  TReportStatus['AddendumAdd'],
  TReportStatus['ProvisionalAddendumSubmit'],
  TReportStatus['AddendumSubmitting'],
  TReportStatus['AddendumCancelling'],
] = [
  ReportStatus.AddendumAdd,
  ReportStatus.ProvisionalAddendumSubmit,
  ReportStatus.AddendumSubmitting,
  ReportStatus.AddendumCancelling,
];

export const SECTION_EDITOR_EDITABLE_STATUSES: [
  TReportStatus['Idle'],
  TReportStatus['Submitting'],
] = [ReportStatus.Idle, ReportStatus.Submitting];

export const ADDENDUM_EDITOR_EDITABLE_STATUSES: [TReportStatus['AddendumAdd']] = [
  ReportStatus.AddendumAdd,
];

export const DISPLAY_DISCARD_REPORT_BUTTON_STATUSES: [
  TReportStatus['Idle'],
  TReportStatus['Submitting'],
  TReportStatus['ProvisionalSubmit'],
  TReportStatus['Cancelling'],
] = [
  ReportStatus.Idle,
  ReportStatus.Submitting,
  ReportStatus.ProvisionalSubmit,
  ReportStatus.Cancelling,
];

export const reportStatusState: RecoilState<ReportStatuses> = atom({
  key: 'reportStatusState',
  default: ReportStatus.Init,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const reporterPreferencesState: RecoilState<{
  sidebarSize: number,
}> = atom({
  key: 'reporterPreferencesState',
  default: {
    sidebarSize: 340,
  },
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

export const pendingTemplateIdState: RecoilState<?string> = atom({
  key: 'pendingTemplateId',
  default: null,
});

export const touchedRequiredFieldsState: RecoilState<TTouchedFieldState> = atom({
  key: 'touchedRequiredFieldsState',
  default: new Map<Key, PathRefType>(),
});

export type TEditableState = 'readOnly' | 'menuOpen' | 'editing';
export const editableStudyDeepLinkState: RecoilState<Map<Key, TEditableState>> = atom({
  key: 'editableStudyDeepLinkState',
  default: new Map<Key, TEditableState>(),
});

export const dictationQueueSizeState: RecoilState<{
  pendingDictations: number,
  pendingWebsocketDictations: number,
  openWebsocketConnections: number,
}> = atom({
  key: 'dictationQueueSizeState',
  default: {
    pendingDictations: 0,
    pendingWebsocketDictations: 0,
    openWebsocketConnections: 0,
  },
});

export const fieldBuilderMode: RecoilState<'text' | 'picklist'> = atom({
  key: 'fieldBuilderMode',
  default: 'text',
});

export const headingErrorState: RecoilState<boolean> = atom({
  key: 'headingErrorState',
  default: false,
});

export const autoCorrectModalState: RecoilState<{
  open: boolean,
  selectionText: string,
}> = atom({
  key: 'autoCorrectModal',
  default: {
    open: false,
    selectionText: '',
  },
});

export const hasReportContentChangedState: RecoilState<boolean> = atom({
  key: 'hasReportContentChanged',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const emptyPlaceholderDialogState: RecoilState<{
  emptyPlaceholders: PlaceholderPluginElement[],
  open: boolean,
}> = atom({
  key: 'placeholderFieldDialog',
  default: {
    emptyPlaceholders: [],
    open: false,
  },
});

export const DEFAULT_REQUIRED_FIELDS_DIALOG_STATE = {
  deleteCallback: NOOP,
  open: false,
  isMultiple: false,
};

export const confirmDeletionRequiredFieldsDialogState: RecoilState<{
  deleteCallback: () => void,
  open: boolean,
  isMultiple: boolean,
}> = atom({
  key: 'confirmDeletionRequiredFieldsDialog',
  default: DEFAULT_REQUIRED_FIELDS_DIALOG_STATE,
});

export const emptyRequiredFieldsDialogRecoilState: RecoilState<{
  emptyRequiredFields: NodeType[],
  open: boolean,
}> = atom({
  key: 'emptyRequiredFieldsDialog',
  default: {
    emptyRequiredFields: [],
    open: false,
  },
});

export const proceduresScopeState: RecoilState<ProcedureScope> = atom({
  key: 'proceduresScope',
  default: 'global',
});

type DiscardReportState = {
  open: boolean,
  discarding: boolean,
  worklistItemSmid: ?string,
  error: string | null,
};

export const discardReportStateDefault = {
  open: false,
  discarding: false,
  worklistItemSmid: null,
  error: null,
};

/*
 * There is a workflow where the user can discard a draft from the worklist.
 * Therefore, this atom needs to manage its state across tabs.
 * This mergeFn is used to reset the state when the user refreshes the reporter page.
 * Practically, this means they can "recover" from the discard action,
 * and view a claimable empty report as opposed to a browse worklist component.
 */
const discardReportMergeFn = (
  oldValue: ?DiscardReportState,
  newValue: DiscardReportState
): DiscardReportState => {
  if (
    oldValue == null &&
    newValue.worklistItemSmid != null &&
    newValue.open === discardReportStateDefault.open &&
    newValue.discarding === discardReportStateDefault.discarding &&
    newValue.error === discardReportStateDefault.error
  ) {
    return {
      ...discardReportStateDefault,
    };
  }
  return newValue;
};

export const discardReportState: RecoilState<DiscardReportState> = atom({
  key: 'discardReportDialog',
  default: discardReportStateDefault,
  effects: [broadcastChannelSynchronizerEffect({ mergeFn: discardReportMergeFn })],
});

export const prevGeneratedImpressionState: RecoilState<string | null> = atom({
  key: 'prevGeneratedImpression',
  default: null,
});

export const draftReportWorklistSmid: RecoilState<?string> = atom({
  key: 'draftReport',
  default: null,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const prevReportSectionsState: RecoilState<string | null> = atom({
  key: 'prevReportSections',
  default: null,
});

export const isFocusModeState: RecoilState<boolean> = atom({
  key: 'isFocusMode',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

export type TUnsignedReportWarningState = {
  open: boolean,
  worklistActionQueued: boolean,
  readyForWorklistAction: boolean,
  id: string,
  eventType: '' | 'read' | 'view',
};

export const defaultUnsignedReportWarningDialogState: TUnsignedReportWarningState = Object.freeze({
  open: false,
  worklistActionQueued: false,
  readyForWorklistAction: false,
  id: '',
  eventType: '',
});

export const unsignedReportDialogState: RecoilState<TUnsignedReportWarningState> = atom({
  key: 'unsignedReportDialogState',
  default: defaultUnsignedReportWarningDialogState,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const isReporterLockedState: RecoilState<boolean> = atom({
  key: 'isReporterLockedState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});
