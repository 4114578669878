// @flow

import React, { Suspense, createContext, useMemo, useRef } from 'react';
import { isAuthlessPathname } from 'utils/router';
import {
  LoadingScreenContext,
  useLoadingScreen,
  usePostLoadState,
} from 'domains/worklist/WorkList/useLoadingScreen';
import { Navigate, Outlet, useLocation, useMatch } from 'react-router-dom';
import useAuth, { AuthProvider } from 'hooks/useAuth';
import { PATH } from 'config/constants';
import { AnimatePresence } from 'framer-motion';
import { Navbar } from '../../Navbar/Navbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import WorkspaceManager from '../../WorkspaceManager';
import { useTrackOpenTabs } from 'hooks/useOpenTabs';
import { useFocusManager } from 'modules/focusManager';
import { useTrackWindowRect } from 'hooks/useWindowsRects';
import { Background as LoginBackground } from '../Auth/Background';
import { PWATitleBar } from '../../ui/PWATitleBar';
import { useInitializeRoles } from 'hooks/useGetUserRoles';
import type { Context } from 'react';
import { useSidebarVisible } from 'domains/viewer/Viewer/viewerState';
import { SIDENAV_WIDTH } from 'common/Layouts/layoutConstants';

export const sidebarPortalContext: Context<{ current: ?HTMLDivElement }> = createContext<{
  current: ?HTMLDivElement,
}>({ current: null });

export default function BackgroundRoute(): React$Node {
  useTrackOpenTabs();
  useFocusManager();
  useTrackWindowRect();

  const location = useLocation();
  const isWorklistPage = useMatch(PATH.WORKLIST) != null;
  const postLoadState = usePostLoadState(isWorklistPage);
  const loadingScreen = useLoadingScreen();
  const sidebarRef = useRef<?HTMLDivElement>();
  const showSidebar = useSidebarVisible();

  const { isAuthenticated, logout, login, loginMFA, error } = useAuth();

  useInitializeRoles(isAuthenticated);

  const auth = useMemo(
    () => ({
      isAuthenticated,
      logout,
      login,
      loginMFA,
      error,
    }),
    [isAuthenticated, logout, login, loginMFA, error]
  );

  // TODO(fzivolo): enable once we are ready to make the navbar toggleable
  const matchPathWorklist = true; // useRouteMatch(PATH.WORKLIST);

  return (
    <LoadingScreenContext.Provider value={loadingScreen}>
      <AuthProvider value={auth}>
        {isWorklistPage && (
          <LoginBackground
            css={`
              position: absolute;
              inset: 0;
              z-index: -1;
              opacity: ${postLoadState && !loadingScreen.showLoadingScreen ? '0' : '1'};
            `}
          />
        )}
        {isAuthlessPathname(location.pathname) && isAuthenticated && (
          <Navigate to={PATH.WORKLIST} />
        )}
        <AnimatePresence>
          {isAuthenticated && postLoadState && (
            <div
              css={`
                z-index: 1;
                max-width: ${showSidebar ? SIDENAV_WIDTH : 0}px;
                overflow-y: hidden;
                transition: max-width 0.3s ease-in;
              `}
            >
              <Navbar
                floating={isWorklistPage}
                logout={logout}
                toggleable={matchPathWorklist == null}
                sidebarRef={sidebarRef}
              />
            </div>
          )}
        </AnimatePresence>

        <div css="position: relative; flex: 1; isolation: isolate;">
          <AnimatePresence initial={false}>
            <Suspense
              fallback={
                <>
                  <PWATitleBar />
                  <LinearProgress />
                </>
              }
            >
              <sidebarPortalContext.Provider value={sidebarRef}>
                <Outlet />
              </sidebarPortalContext.Provider>
            </Suspense>
          </AnimatePresence>
          {isAuthenticated && <WorkspaceManager />}
        </div>
      </AuthProvider>
    </LoadingScreenContext.Provider>
  );
}
